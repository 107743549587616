import React from "react";
import { graphql, StaticQuery } from "gatsby";
import styled from "styled-components";
import PropTypes from "prop-types";

import BackgroundImage from "gatsby-background-image";

const SeoWissenHeader = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "photo-1573804633927-bfcbcd909acd.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1280) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `}
    render={(data) => {
      // Set ImageData.
      const imageData = [
        data.desktop.childImageSharp.fluid,
        `linear-gradient(to right, rgba(45, 81, 125, .9),rgba(45, 81, 125, .9) 80%)`,
      ].reverse();

      return (
        <BackgroundImage
          Tag="div"
          className={className}
          fluid={imageData}
          loading="eager"
          fadeIn={false}
        >
      <div className="relative pt-6 pb-6 sm:pb-8 md:pb-20 lg:pb-14 xl:pb-16">
        <main className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-6 sm:px-6 md:mt-8 lg:mt-10 xl:mt-28">
          <div className="text-center">
            <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-white sm:text-5xl sm:leading-none md:text-6xl">
              SEO-
              <span className="text-orange-600">Wissen</span>
            </h2>
            <p className="mt-3 max-w-md mx-auto text-left md:text-center text-base text-gray-400 sm:text-lg md:mt-5 md:text-xl md:max-w-4xl">
              Ganz neu in der Suchmaschinenoptimierung? Bereits vorhandenes Know-How erweitern? Hier bist du genau richtig!
              Mit meinen Inhalten
              verschaffst du dir ein solides Grundwissen über die wichtigsten
              Begriffe und Konzepte. In den spezifischeren Bereichen kannst du
              dein Wissen dann erweitern, um für das nächste Google-Update
              gerüstet zu sein. Für spezielle Anfragen kannst du mich gerne
              kontaktieren.
            </p>
          </div>
        </main>
      </div>
        </BackgroundImage>
      );
    }}
  />
);

SeoWissenHeader.propTypes = {
  className: PropTypes.string.isRequired,
};

const StyledSeoWissenHeader = styled(SeoWissenHeader)`
  width: 100%;
  min-height: 250px;
  background-position: 50% 30%;
  background-repeat: no-repeat;
  background-size: cover;
`;

export default StyledSeoWissenHeader;
