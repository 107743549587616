import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import StyledSeoWissenHeader from "../components/sections/seo-wissen-header";
import SeoWissenElement from "../components/elements/seo-wissen-element";

function WissenPage() {
  return (
    <Layout>
      <SEO
        title="SEO-Wissen"
      />
      <StyledSeoWissenHeader className="relative bg-blue-800 overflow-hidden" />
      <SeoWissenElement />
    </Layout>
  );
}

export default WissenPage;
